// Here you can add other styles
body{
    font-family: "Nunito", sans-serif;
    color: #201e1f;
}
h3{
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.167;
}

// SIDEBAR
.sidebar-brand{
    padding: 10px 0;
}

// HEADER
.header-toggler{
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    position: relative;
    border-radius: 0 10px 10px 0;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: #e9e9e9;
    transition: all .3s ease;
    margin-left: -20px;

    span{
        display: table;
        position: relative;
        width: 100%;
        height: 3px;
        background-color: #1b50ac;

        &:before, &:after{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            display: table;
            width: 100%;
            height: 100%;
            background-color: inherit;
        }

        &:before{
            top: -10px;
        }

        &:after{
            bottom: -10px;
            width: 60%;
        }
    }

    &:hover{
        background-color: #1b50ac;

        span{
            background-color: #FFFFFF;
        }
    }
}

.avatar{
    border: 1px solid #e9e9e9;
    padding: 5px;
}

.header-divider{
    border-color: #1b50ac;
    background-color: #1b50ac;
}

.header{
    border-bottom: 1px solid #1b50ac;

    .header-divider{
        margin: 0;
    }
}

.breadcrumb{
    .breadcrumb-item{
        color: rgba($color: #201e1f, $alpha: 0.5);
        font-weight: 700;
        
        &.active{
            color: #201e1f;
        }

        &:not(.active){
            &:hover{
                color: #1b50ac;
                cursor: pointer;
            }
        }
    }
}

.header-nav{
    .icon_arrowdown{
        margin-left: 5px;
    }
}

// DASHBOARD
.dashboard_card{
    width: 100%;
    padding: 8px 18px 20px 8px;
    background: rgba(209, 219, 239, 0.2);
    // background-color: #FFFFFF;
    border-radius: 10px;
    border: none;
    text-align: left;

    .dashboard_cardbody_container{
        display: flex;
        align-items: center;
    }

    .icon_wrapper{
        width: 20%;
        
        .icon:not(.icon-c-s):not(.icon-custom-size).icon-lg{
            width: 80%;
            height: auto;
            opacity: 0.1;
            color: #1b4caf;
        }
    }

    .card-title{
        margin: 0px;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0.02em;
        line-height: 1.167;
    }

    .card-text{
        color: #201e1f;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.235;
    }
}

.bar_canvas_wrapper{
    width: 100%;
    border: 1px solid rgba(27, 76, 175, .2);
    padding: 10px;
    border-radius: 10px;
}

.table_wrapper{
    width: 100%;
    border: 1px solid rgba(27, 76, 175, .2);
    padding: 10px 11px 16px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #FFFFFF;
    overflow: auto;
}

table.table{
    width: 100%;
    min-width: 1000px;
    border-collapse: separate;
    border-spacing: 0px;
    margin: 0;

    thead{
        tr{
            th, td{
                color: #1b4caf;
                font-size: 15px;
                font-weight: 700;
                line-height: 1.4;
                padding: 9px 15px;
                border: none;
                border-width: 1px 0 1px;
                border-style: solid;
                border-color: #1b4caf33;
                background-color: #d1dbef33;

                &:first-child{
                    border-left-width: 1px;
                    border-radius: 10px 0 0 10px;
                }
                &:last-child{
                    border-right-width: 1px;
                    border-radius: 0 10px 10px 0;
                }
            }
        }
    }
    tbody{
        &:before{
            content: "";
            display: block;
            height: 10px;
        }

        tr{
            th, td{
                font-size: 13px;
                line-height: 1.3;
                font-weight: 500;
                padding: 11px 15px;
                border: none;
                vertical-align: middle;
                border-width: 0;
                border-style: solid;
                border-color: #1b4caf33;
                background-color: #FFFFFF;

                &:first-child{
                    border-left-width: 1px;
                }
                &:last-child{
                    border-right-width: 1px;
                }
            }

            &:first-child{
                th, td{
                    border-top-width: 1px;
                    
                    &:first-child{
                        border-top-left-radius: 10px;
                    }
                    &:last-child{
                        border-top-right-radius: 10px;
                    }
                }
            }

            &:last-child{
                th, td{
                    border-bottom-width: 1px;

                    &:first-child{
                        border-bottom-left-radius: 10px;
                    }
                    &:last-child{
                        border-bottom-right-radius: 10px;
                    }
                }
            }

            &:nth-child(odd){
                th, td{
                    background-color: #e9e9e933;
                }
            }
        }
    }
}

// ADD SERVICE
.profile-picture-wrapper{
    width: 100%;
}
.profile-picture-container{
    width: 100%;
    max-width: 150px;
}